import { useEffect, useState } from "react";
import { DedicatedChat } from "@telia/b2x-rest-client/types/generated/services/support-corp-contact";

import {
  SEARCH_INTERVAL_IN_SECONDS,
  SEARCH_UP_TO_IN_SECONDS,
} from "../../constants/stockholm-chat-card";
import { UserData } from "../../providers/user-data/UserDataContext";
import { selectChatbotFromDOM } from "../../utils/chat";
import { isBetweenTime, isWeekend } from "../../utils/time-utils";
import { useUserData } from "../use-user-data/use-user-data";

export const dedicatedChatSelector = (userData: UserData | null): DedicatedChat | undefined => {
  return userData?.corpContact?.dedicatedChat;
};

const useChat = (): boolean => {
  const dedicatedChat = useUserData(dedicatedChatSelector);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const isBetweenWorkingHours = isWeekend()
      ? isBetweenTime(dedicatedChat?.weekendHourStartChat, dedicatedChat?.weekendHourEndChat, true)
      : isBetweenTime(dedicatedChat?.workingHourStartChat, dedicatedChat?.workingHourEndChat);

    if (!isBetweenWorkingHours) {
      setIsAvailable(false);
      return;
    }

    let secondsPassed = 0;
    const chatbotSearchInterval = setInterval(() => {
      if (secondsPassed >= SEARCH_UP_TO_IN_SECONDS) {
        clearInterval(chatbotSearchInterval);
      }
      if (selectChatbotFromDOM()) {
        setIsAvailable(true);
        clearInterval(chatbotSearchInterval);
      }
      secondsPassed += SEARCH_INTERVAL_IN_SECONDS;
    }, SEARCH_INTERVAL_IN_SECONDS * 1000);

    return () => {
      clearInterval(chatbotSearchInterval);
    };
  }, [dedicatedChat]);

  return isAvailable;
};

export { useChat };
