import { useEffect, useState } from "react";
import { onceConsented } from "@telia/b2x-cookie-consent";

export const useCookieConsent = (): { hasConsent: boolean } => {
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    onceConsented("C0001", "humany-chattbot").then(() => setHasConsent(true));
  }, []);

  return { hasConsent };
};
