// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R2ppYIZFgKzcK6McV9Fd{padding:calc(2.4rem*var(--voca-rem-multiplier)) 0px}`, "",{"version":3,"sources":["webpack://./src/components/cards-layout/CardsLayout.module.scss"],"names":[],"mappings":"AAEA,sBACE,mDAAA","sourcesContent":["@import \"~@teliads/components/foundations/spacing/variables\";\n\n.cardsContainer {\n  padding: $telia-spacing-24 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsContainer": `R2ppYIZFgKzcK6McV9Fd`
};
export default ___CSS_LOADER_EXPORT___;
