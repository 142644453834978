import React, { useMemo } from "react";
import { t } from "@telia/b2b-microcopy-translation";
import { B2xCard } from "@telia/b2x-card/react-cjs";
import { TechnicianResponseBody } from "@telia/b2x-support-rest-client/dist/support-corp-technician/models/TechnicianResponseBody";
import { TeliaP } from "@teliads/components/react/commonjs";

import { useUserData } from "../../hooks/use-user-data/use-user-data";
import { scopeIdSelector } from "../../selectors/scope-id-selector";
import { translationKeys } from "../../translation";
import IconLink from "../icon-link/IconLink";
import styles from "./PersonalTechnicianCard.module.scss";

const { personalTechnician } = translationKeys;

export type Props = {
  technicianData?: TechnicianResponseBody;
};

const PersonalTechnicianCard = ({ technicianData }: Props) => {
  const scopeId = useUserData(scopeIdSelector);

  const cardHeading = useMemo(
    () =>
      technicianData?.firstname
        ? `${t(personalTechnician.heading)}, ${technicianData?.firstname}`
        : t(personalTechnician.heading),
    [technicianData?.firstname]
  );

  const createTicketLink = useMemo(
    () => (scopeId ? `/foretag/mybusiness/${scopeId}/support/arenden/fraga` : ""),
    [scopeId]
  );

  return (
    <B2xCard
      heading={cardHeading}
      backgroundColor="light"
      data-testid="personal-technician-card"
      className={styles.cardWrapper}
    >
      <TeliaP data-testid="pt-description">{t(personalTechnician.description)}</TeliaP>
      <TeliaP className={styles.contactLabelWrapper}>
        <span className={styles.contactLabel} data-testid="pt-contact-label">
          {t(personalTechnician.contactLabel)}
        </span>
      </TeliaP>
      <TeliaP data-testid="pt-work-hours">{t(personalTechnician.details.workHoursHeader)}</TeliaP>
      {technicianData?.phone && (
        <div className={styles.actionLink}>
          <IconLink
            text={technicianData?.phone}
            href={`tel:${technicianData?.phone}`}
            iconName="phone"
          />
        </div>
      )}
      <IconLink text={t(personalTechnician.buttonText)} href={createTicketLink} iconName="email" />
    </B2xCard>
  );
};

export { PersonalTechnicianCard };
