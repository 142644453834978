import React from "react";
import { B2xChatLoader } from "@telia/b2x-chat-loader/react-esm";

import { ChatType } from "../../enums/chat-type";
import { CompanySegment } from "../../enums/company-segment";
import { useCookieConsent } from "../../hooks/use-cookie-consent/use-cookie-consent";
import { useUserData } from "../../hooks/use-user-data/use-user-data";
import { UserData } from "../../providers/user-data/UserDataContext";
import { isBetweenTime, isWeekend } from "../../utils/time-utils";

const eligibleSegments = [CompanySegment.SME, CompanySegment.SOHO];
const selectDedicatedChat = (userData: UserData | null) => userData?.corpContact?.dedicatedChat;

const Chat = () => {
  const { companySegment } = useUserData();
  const dedicatedChat = useUserData(selectDedicatedChat);
  const { hasConsent } = useCookieConsent();

  const companySegmentEligible = eligibleSegments.some((segment) => segment === companySegment);

  const isChatEnabled = () => {
    const isRegionOfStockholm = dedicatedChat?.chatType === ChatType.LARGE_LIVE_CHAT;
    const isValidWorkHours = isWeekend()
      ? isBetweenTime(dedicatedChat?.weekendHourStartChat, dedicatedChat?.weekendHourEndChat, true)
      : isBetweenTime(dedicatedChat?.workingHourStartChat, dedicatedChat?.workingHourEndChat);

    if (!hasConsent) {
      return false;
    }

    if (isRegionOfStockholm && isValidWorkHours) {
      return true;
    }

    if (!companySegmentEligible) {
      return false;
    }

    return true;
  };

  return isChatEnabled() ? <B2xChatLoader type="b2x-chat-loader-b2b-contact" /> : null;
};

export default Chat;
