import React, { Fragment, useMemo } from "react";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import { t } from "@telia/b2b-microcopy-translation";
import { TeliaP } from "@teliads/components/react";
import {
  TeliaGrid,
  TeliaNotification,
  TeliaSkeleton,
  TeliaVisuallyHidden,
} from "@teliads/components/react/commonjs";

import { CardsLayout } from "../components/cards-layout/CardsLayout";
import { PersonalTechnicianCard } from "../components/personal-technician-card/PersonalTechnicianCard";
// import QuestionCard from "../components/question-card/QuestionCard";
import { SalesContactCard } from "../components/sales-contact-card/SalesContactCard";
import { ServiceDeskCard } from "../components/service-desk-card/ServiceDeskCard";
import { StockholmChatCard } from "../components/stockholm-chat-card/StockholmChatCard";
import { salesTeamsList } from "../constants/sales-teams-list";
import { ChatType } from "../enums/chat-type";
import { ProviderStatus } from "../enums/provider-status";
import { useUserData } from "../hooks/use-user-data/use-user-data";
import { translationKeys } from "../translation";
import styles from "./Contact.module.scss";

const shouldShowSalesContact = (salesTeam: string | undefined) =>
  salesTeam && salesTeamsList.includes(salesTeam);

const Contact = () => {
  const { corpContact, technicianData, status, updateSelectedOrganisation, selectedOrganisation } =
    useUserData() || {};
  const { salesAgent, dedicatedChat } = corpContact || {};

  const isAnyContactFailed = status
    ? Object.values(status).some((contactTypeStatus) => contactTypeStatus === ProviderStatus.FAILED)
    : false;

  const isAnyContactLoading = status
    ? Object.values(status).some(
        (contactTypeStatus) => contactTypeStatus === ProviderStatus.LOADING
      )
    : false;

  const isRegionOfStockholm = dedicatedChat?.chatType === ChatType.LARGE_LIVE_CHAT;

  // solution for conditional rendering of the cards: each card has it's own condition
  const cards = useMemo(() => {
    const componentRegistry = [
      technicianData && (
        <PersonalTechnicianCard technicianData={technicianData} key="personal-technician" />
      ),
      <ServiceDeskCard key="service-desk" data-testid="service-desk-card" />,
      shouldShowSalesContact(salesAgent?.salesTeam) && <SalesContactCard key="sales-contact" />,
      isRegionOfStockholm && (
        <StockholmChatCard key="stockholm-chat-card" dedicatedChat={dedicatedChat} />
      ),
      // <QuestionCard key="question" />,
    ];

    return componentRegistry.filter((card): card is JSX.Element => !!card);
  }, [dedicatedChat, salesAgent?.salesTeam, technicianData, isRegionOfStockholm]);

  const skeleton = [
    <Fragment key="loader">
      <TeliaVisuallyHidden>{t(translationKeys.cards.loadingPlaceholder)}</TeliaVisuallyHidden>
      <TeliaSkeleton className={styles.skeleton} data-testid="skeleton" />
    </Fragment>,
  ];

  return (
    <B2bLayout
      showOrganisationSelector={true}
      heading={t(translationKeys.header.heading)}
      pageTitle={t(translationKeys.header.heading)}
      selectedOrganisation={selectedOrganisation?.tscid}
      {...{
        onOrganisationSelected: (event: CustomEvent) => {
          updateSelectedOrganisation(event.detail);
        },
      }}
    >
      <section>
        <TeliaGrid>
          {isAnyContactFailed && (
            <div className={styles.cardsError}>
              <TeliaNotification dataTestid="contact-error-message" status="error" headingTag="h1">
                <span slot="heading">{t("contact.cards.error.heading")}</span>
                <TeliaP slot="content">{t("contact.cards.error.content")}</TeliaP>
              </TeliaNotification>
            </div>
          )}
          <CardsLayout>{isAnyContactLoading ? skeleton : cards}</CardsLayout>
        </TeliaGrid>
      </section>
    </B2bLayout>
  );
};

export default Contact;
