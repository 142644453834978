import React from "react";
import { IconProps } from "@teliads/components/dist/types/types/IconProperties";
import { TeliaIcon, TeliaLink } from "@teliads/components/react/commonjs";

import styles from "./IconLink.module.scss";

type IconLinkProps = {
  text: string;
  href: string;
  iconName?: IconProps["name"];
};

const IconLink = ({ text, href, iconName }: IconLinkProps) => {
  return text && href ? (
    <TeliaLink variant="standalone" href={href} className={styles.link} data-testid="redirect-link">
      <TeliaIcon slot="left" className={styles.icon} name={iconName} size="sm" />
      {text}
    </TeliaLink>
  ) : null;
};

export default IconLink;
