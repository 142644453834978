import React from "react";
import { TeliaStatusBadge } from "@teliads/components/react";

import styles from "./AvailabilityBadge.module.scss";

type AvailabilityBadgeProps = {
  isAvailable: boolean;
  availableBadgeText: string;
  notAvailableBadgeText: string;
};

const AvailabilityBadge = ({
  isAvailable,
  availableBadgeText,
  notAvailableBadgeText,
}: AvailabilityBadgeProps) => {
  return isAvailable ? (
    <TeliaStatusBadge
      variant="positive"
      icon="check-circle"
      className={styles.availabilityBadge}
      data-testid="card-badge-open"
    >
      {availableBadgeText}
    </TeliaStatusBadge>
  ) : (
    <TeliaStatusBadge
      variant="neutral"
      icon="stop"
      className={styles.availabilityBadge}
      data-testid="card-badge-closed"
    >
      {notAvailableBadgeText}
    </TeliaStatusBadge>
  );
};

export { AvailabilityBadge };
