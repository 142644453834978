import { createContext } from "react";
import { GetOrganizationResponse as Organisation } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { ContactsResponse } from "@telia/b2x-rest-client/types/generated/services/support-corp-contact";
import { TechnicianResponseBody } from "@telia/b2x-support-rest-client/dist/support-corp-technician/models/TechnicianResponseBody";

import { CompanySegment } from "../../enums/company-segment";
import { ProviderStatus } from "../../enums/provider-status";

export type UserData = {
  status: {
    scopeId: ProviderStatus;
    organisation: ProviderStatus;
    corpContact: ProviderStatus;
    companySegment: ProviderStatus;
    personalTechnician: ProviderStatus;
  };
  scopeId?: string;
  organisations?: Organisation[];
  selectedOrganisation?: Organisation;
  updateSelectedOrganisation: (organisationTscIds: string) => void;
  corpContact?: ContactsResponse;
  companySegment?: CompanySegment;
  technicianData?: TechnicianResponseBody;
};

export const UserDataContext = createContext<UserData | null>(null);
