import React from "react";
import { t } from "@telia/b2b-microcopy-translation";
import { B2xCard } from "@telia/b2x-card/react-cjs";
import { TeliaP, TeliaSkeleton, TeliaVisuallyHidden } from "@teliads/components/react/commonjs";

import { useUserData } from "../../hooks/use-user-data/use-user-data";
import { translationKeys } from "../../translation";
import IconLink from "../icon-link/IconLink";
import styles from "./SalesContactCard.module.scss";

const { salesContact } = translationKeys;
const SalesContactCard = () => {
  const userData = useUserData();
  const { corpContact } = userData || {};

  return (
    <B2xCard
      heading={t(salesContact.heading)}
      backgroundColor="light"
      data-testid="card"
      className={styles.cardWrapper}
    >
      <TeliaP className={styles.preamble}>{t(salesContact.preamble)}</TeliaP>
      {/*TODO: change to loading state when impelemted in provider*/}
      {corpContact ? (
        <>
          <TeliaP>
            <strong>{corpContact?.salesAgent?.name}</strong>
          </TeliaP>

          <IconLink
            text={corpContact?.salesAgent?.email || ""}
            href={`mailto:${corpContact?.salesAgent?.email}`}
            iconName="email"
            data-testid="email"
          />

          <IconLink
            text={corpContact?.salesAgent?.phone || ""}
            href={`tel:${corpContact?.salesAgent?.phone}`}
            iconName="phone"
            data-testid="number"
          />
        </>
      ) : (
        <>
          <TeliaVisuallyHidden>{t(salesContact.loadingPlaceholder)}</TeliaVisuallyHidden>
          <TeliaSkeleton className={styles.skeleton} data-testid="skeleton" />
        </>
      )}
    </B2xCard>
  );
};

export { SalesContactCard };
