import React, { useCallback, useEffect, useState } from "react";

import { FALLBACK_SUPPORT } from "../../constants/fallback-support";
import { ProviderStatus } from "../../enums/provider-status";
import { useUserData } from "../../hooks/use-user-data/use-user-data";
import { UserData } from "../../providers/user-data/UserDataContext";
import { isBetweenTime } from "../../utils/time-utils";
import Card from "./card/Card";
import { serviceDeskCardStateMapper } from "./mappers/service-desk-card-state-mapper";

export type AssignedContact = {
  workHourStart: string;
  workHourEnd: string;
  supportNumber: string;
  isAvailable: boolean;
};

const selectDedicatedChat = (userData: UserData | null) => userData?.corpContact?.dedicatedChat;

const selectDedicatedChatStatus = (userData: UserData | null) => userData?.status.corpContact;

const fallbackCard = (
  <Card
    isAvailable={isBetweenTime(FALLBACK_SUPPORT.WORK_HOUR_START, FALLBACK_SUPPORT.WORK_HOUR_END)}
    workHourStart={FALLBACK_SUPPORT.WORK_HOUR_START}
    workHourEnd={FALLBACK_SUPPORT.WORK_HOUR_END}
    telephone={FALLBACK_SUPPORT.SUPPORT_NUMBER}
  />
);

const constructSuccessCard = (assignedContact: AssignedContact): JSX.Element => (
  <Card
    isAvailable={assignedContact.isAvailable}
    workHourStart={assignedContact.workHourStart}
    workHourEnd={assignedContact.workHourEnd}
    telephone={assignedContact.supportNumber}
  />
);

const ServiceDeskCard = () => {
  const dedicatedChat = useUserData(selectDedicatedChat);
  const dedicatedChatStatus = useUserData(selectDedicatedChatStatus);

  const [assignedContact, setAssignedContact] = useState<AssignedContact>();

  useEffect(() => {
    dedicatedChat && setAssignedContact(serviceDeskCardStateMapper(dedicatedChat));
  }, [dedicatedChat]);

  const renderServiceDeskCardByStatus = useCallback(
    (dedicatedChatStatus?: ProviderStatus) => {
      if (!dedicatedChatStatus) {
        return null;
      }
      if (!assignedContact) {
        return fallbackCard;
      }
      const statusMap = {
        [ProviderStatus.LOADING]: null,
        [ProviderStatus.SUCCESS]: constructSuccessCard(assignedContact),
        [ProviderStatus.FAILED]: fallbackCard,
      };
      return statusMap[dedicatedChatStatus];
    },
    [assignedContact]
  );

  return renderServiceDeskCardByStatus(dedicatedChatStatus);
};

export { ServiceDeskCard };
