import { DedicatedChat } from "@telia/b2x-rest-client/types/generated/services/support-corp-contact";

import { parseUTCTimeString } from "./time-utils";

export const parseDedicatedChatDates = (dedicatedChat: DedicatedChat): DedicatedChat => {
  return {
    ...dedicatedChat,
    workingHourStartChat: parseUTCTimeString(dedicatedChat.workingHourStartChat),
    workingHourStartCall: parseUTCTimeString(dedicatedChat.workingHourStartCall),
    workingHourEndChat: parseUTCTimeString(dedicatedChat.workingHourEndChat),
    workingHourEndCall: parseUTCTimeString(dedicatedChat.workingHourEndCall),
    weekendHourStartChat: parseUTCTimeString(dedicatedChat.weekendHourStartChat),
    weekendHourEndChat: parseUTCTimeString(dedicatedChat.weekendHourEndChat),
  };
};
