// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RySFCJ0vqm4iHyGIUnO4{margin-left:calc(0.8rem*var(--voca-rem-multiplier))}`, "",{"version":3,"sources":["webpack://./src/components/availability-badge/AvailabilityBadge.module.scss","webpack://./../../common/temp/node_modules/.pnpm/@teliads+components@22.1.1/node_modules/@teliads/components/foundations/spacing/tokens.scss"],"names":[],"mappings":"AAEA,sBACI,mDCGc","sourcesContent":["@import \"~@teliads/components/foundations/spacing/variables\";\n\n.availabilityBadge {\n    margin-left: $telia-spacing-8;\n}","@import '../tokens/dynamic-rem';\n\n$telia-spacing-0: dynamic-rem(0rem);\n$telia-spacing-1: dynamic-rem(0.1rem);\n$telia-spacing-2: dynamic-rem(0.2rem);\n$telia-spacing-4: dynamic-rem(0.4rem);\n$telia-spacing-8: dynamic-rem(0.8rem);\n$telia-spacing-12: dynamic-rem(1.2rem);\n$telia-spacing-16: dynamic-rem(1.6rem);\n$telia-spacing-20: dynamic-rem(2rem);\n$telia-spacing-24: dynamic-rem(2.4rem);\n$telia-spacing-32: dynamic-rem(3.2rem);\n$telia-spacing-48: dynamic-rem(4.8rem);\n$telia-spacing-64: dynamic-rem(6.4rem);\n$telia-spacing-80: dynamic-rem(8rem);\n$telia-spacing-96: dynamic-rem(9.6rem);\n$telia-spacing-128: dynamic-rem(12.8rem);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availabilityBadge": `RySFCJ0vqm4iHyGIUnO4`
};
export default ___CSS_LOADER_EXPORT___;
