import React from "react";
import { t } from "@telia/b2b-microcopy-translation";
import { B2xCard } from "@telia/b2x-card/react-cjs";
import { TeliaHeading, TeliaP } from "@teliads/components/react/commonjs";

import { translationKeys } from "../../../translation";
import { AvailabilityBadge } from "../../availability-badge/AvailabilityBadge";
import IconLink from "../../icon-link/IconLink";
import { WorkHourDisplay } from "../../work-hour-display/WorkHourDisplay";
import styles from "./Card.module.scss";

const { serviceDeskCard } = translationKeys;

export type CardProps = {
  isAvailable: boolean;
  workHourStart?: string;
  workHourEnd?: string;
  telephone: string;
};

const Card = ({ isAvailable, workHourStart, workHourEnd, telephone }: CardProps) => (
  <B2xCard
    heading={t(serviceDeskCard.heading)}
    backgroundColor="light"
    className={styles.cardWrapper}
  >
    <div data-testid="card-content">
      <TeliaP className={styles.description}>{t(serviceDeskCard.preamble)}</TeliaP>
      <div className={styles.badgeWrapper}>
        <TeliaHeading
          tag="h3"
          variant="title-100"
          className={styles.badgeHeader}
          data-testid="header"
        >
          {t(serviceDeskCard.badgeHeader)}
        </TeliaHeading>
        <AvailabilityBadge
          isAvailable={isAvailable}
          availableBadgeText={t(serviceDeskCard.badgeValue.open)}
          notAvailableBadgeText={t(serviceDeskCard.badgeValue.closed)}
        />
      </div>
      <WorkHourDisplay
        startHour={workHourStart}
        endHour={workHourEnd}
        header={t(serviceDeskCard.workHoursHeader)}
      />
      <div className={styles.actionLink}>
        <IconLink text={telephone} href={`tel:${telephone}`} iconName="phone" />
      </div>
    </div>
  </B2xCard>
);

export default Card;
