import React, { useCallback } from "react";
import { t } from "@telia/b2b-microcopy-translation";
import { B2xCard } from "@telia/b2x-card/react-cjs";
import { DedicatedChat } from "@telia/b2x-rest-client/types/generated/services/support-corp-contact";
import { TeliaButton, TeliaHeading, TeliaP } from "@teliads/components/react/commonjs";

import { CHABOT_CLASSNAME } from "../../constants/stockholm-chat-card";
import { useChat } from "../../hooks/use-chat/use-chat";
import { LoggingService } from "../../services/logging-service/logging-service";
import { translationKeys } from "../../translation";
import { selectChatbotFromDOM } from "../../utils/chat";
import { AvailabilityBadge } from "../availability-badge/AvailabilityBadge";
import { WorkHourDisplay } from "../work-hour-display/WorkHourDisplay";
import styles from "./StockholmChatCard.module.scss";

const { stockholmChatCard } = translationKeys;

export type StockholmChatCardProps = {
  dedicatedChat?: DedicatedChat;
};

const StockholmChatCard = ({ dedicatedChat }: StockholmChatCardProps) => {
  const isAvailable = useChat();

  const handleStartChatClick = useCallback(() => {
    if (!window.humany) {
      return;
    }
    try {
      const chatbot = selectChatbotFromDOM();
      chatbot.click();
    } catch (e) {
      LoggingService.logError(
        `Query selector failed finding a chatbot element which has class '${CHABOT_CLASSNAME}'`
      );
    }
  }, []);

  return (
    <B2xCard
      heading={t(stockholmChatCard.heading)}
      backgroundColor="light"
      className={styles.cardWrapper}
      data-testid="stockholm-chat-card"
    >
      <div data-testid="card-content">
        <TeliaP className={styles.description}>{t(stockholmChatCard.preamble)}</TeliaP>
        <div className={styles.badgeWrapper}>
          <TeliaHeading
            tag="h3"
            variant="title-100"
            className={styles.badgeHeader}
            data-testid="header"
          >
            {t(stockholmChatCard.badgeHeader)}
          </TeliaHeading>
          <AvailabilityBadge
            isAvailable={isAvailable}
            availableBadgeText={t(stockholmChatCard.badgeValue.open)}
            notAvailableBadgeText={t(stockholmChatCard.badgeValue.closed)}
          />
        </div>
        <WorkHourDisplay
          startHour={dedicatedChat?.workingHourStartChat}
          endHour={dedicatedChat?.workingHourEndChat}
          header={t(stockholmChatCard.workHoursHeader)}
        />
        <WorkHourDisplay
          startHour={dedicatedChat?.weekendHourStartChat}
          endHour={dedicatedChat?.weekendHourEndChat}
          header={t(stockholmChatCard.weekendWorkHoursHeader)}
        />
        <TeliaButton
          variant="tertiary-purple"
          type="button"
          leftIcon={{
            name: "chat",
            size: "sm",
            allyTitle: "Start region of Stockholm chat",
            dataTestid: "chat-icon",
          }}
          onClick={handleStartChatClick}
          data-testid="chat-button"
          text={t(stockholmChatCard.buttonText)}
          disabled={!isAvailable}
        />
      </div>
    </B2xCard>
  );
};

export { StockholmChatCard };
