// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c938wzKSqh26VZg_rnKq{margin-right:calc(0.8rem*var(--voca-rem-multiplier))}.c938wzKSqh26VZg_rnKq :first-child{margin-top:calc(0.2rem*var(--voca-rem-multiplier))}.IaVmUJ_UYa7rx04Kbl8w{overflow-wrap:anywhere}`, "",{"version":3,"sources":["webpack://./src/components/icon-link/IconLink.module.scss","webpack://./../../common/temp/node_modules/.pnpm/@teliads+components@22.1.1/node_modules/@teliads/components/foundations/spacing/tokens.scss"],"names":[],"mappings":"AAGA,sBACE,oDCEgB,CDClB,mCACE,kDCJgB,CDOlB,sBACE,sBAAA","sourcesContent":["@import \"~@teliads/components/foundations/spacing/variables\";\n\n// TODO: find out why this icon styling is needed. Global page styling resets Voca link component styling, it shouldn't be like that.\n.icon {\n  margin-right: $telia-spacing-8;\n}\n\n.icon :first-child {\n  margin-top: $telia-spacing-2;\n}\n\n.link {\n  overflow-wrap: anywhere;\n}\n","@import '../tokens/dynamic-rem';\n\n$telia-spacing-0: dynamic-rem(0rem);\n$telia-spacing-1: dynamic-rem(0.1rem);\n$telia-spacing-2: dynamic-rem(0.2rem);\n$telia-spacing-4: dynamic-rem(0.4rem);\n$telia-spacing-8: dynamic-rem(0.8rem);\n$telia-spacing-12: dynamic-rem(1.2rem);\n$telia-spacing-16: dynamic-rem(1.6rem);\n$telia-spacing-20: dynamic-rem(2rem);\n$telia-spacing-24: dynamic-rem(2.4rem);\n$telia-spacing-32: dynamic-rem(3.2rem);\n$telia-spacing-48: dynamic-rem(4.8rem);\n$telia-spacing-64: dynamic-rem(6.4rem);\n$telia-spacing-80: dynamic-rem(8rem);\n$telia-spacing-96: dynamic-rem(9.6rem);\n$telia-spacing-128: dynamic-rem(12.8rem);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `c938wzKSqh26VZg_rnKq`,
	"link": `IaVmUJ_UYa7rx04Kbl8w`
};
export default ___CSS_LOADER_EXPORT___;
