// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oAfROCauD5oV5OTuStjw{animation:L5Mhky4xo3qmWT3z0tAR .5s ease-in-out}@keyframes L5Mhky4xo3qmWT3z0tAR{from{opacity:0}to{opacity:1}}.gkBworBANCl_TL8m7Q0t{margin-bottom:calc(2.4rem*var(--voca-rem-multiplier))}.WKJ9li51EdK_D4REZZRU{height:calc(9.6rem*var(--voca-rem-multiplier))}`, "",{"version":3,"sources":["webpack://./src/components/sales-contact-card/SalesContactCard.module.scss","webpack://./src/styles/_mixins.scss","webpack://./../../common/temp/node_modules/.pnpm/@teliads+components@22.1.1/node_modules/@teliads/components/foundations/spacing/tokens.scss"],"names":[],"mappings":"AAGA,sBCFE,8CAAA,CAEA,gCACE,KACE,SAAA,CAGF,GACE,SAAA,CAAA,CDFN,sBACE,qDEEiB,CFCnB,sBACE,8CEGiB","sourcesContent":["@import \"~@teliads/components/foundations/spacing/variables\";\n@import \"../../styles/_mixins.scss\";\n\n.cardWrapper {\n  @include fadeIn();\n}\n\n.preamble {\n  margin-bottom: $telia-spacing-24;\n}\n\n.skeleton {\n  height: $telia-spacing-96;\n}\n","@mixin fadeIn() {\n  animation: fadeIn 0.5s ease-in-out;\n\n  @keyframes fadeIn {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n}\n","@import '../tokens/dynamic-rem';\n\n$telia-spacing-0: dynamic-rem(0rem);\n$telia-spacing-1: dynamic-rem(0.1rem);\n$telia-spacing-2: dynamic-rem(0.2rem);\n$telia-spacing-4: dynamic-rem(0.4rem);\n$telia-spacing-8: dynamic-rem(0.8rem);\n$telia-spacing-12: dynamic-rem(1.2rem);\n$telia-spacing-16: dynamic-rem(1.6rem);\n$telia-spacing-20: dynamic-rem(2rem);\n$telia-spacing-24: dynamic-rem(2.4rem);\n$telia-spacing-32: dynamic-rem(3.2rem);\n$telia-spacing-48: dynamic-rem(4.8rem);\n$telia-spacing-64: dynamic-rem(6.4rem);\n$telia-spacing-80: dynamic-rem(8rem);\n$telia-spacing-96: dynamic-rem(9.6rem);\n$telia-spacing-128: dynamic-rem(12.8rem);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": `oAfROCauD5oV5OTuStjw`,
	"fadeIn": `L5Mhky4xo3qmWT3z0tAR`,
	"preamble": `gkBworBANCl_TL8m7Q0t`,
	"skeleton": `WKJ9li51EdK_D4REZZRU`
};
export default ___CSS_LOADER_EXPORT___;
