export const parseUTCTimeString = (UTCTimeString: string | undefined): string =>
  UTCTimeString ? UTCTimeString.slice(0, 5) : "";

export const isWeekend = (): boolean =>
  new Date().getUTCDay() === 0 || new Date().getUTCDay() === 6;

export const parseHourStringFromDate = (currentDate: Date | undefined): string => {
  return currentDate ? `${currentDate.getHours()} - ${currentDate.getMinutes()}` : "";
};

export const returnSwedishTimeString = (): string => {
  return new Date().toLocaleString("en-GB", { timeZone: "Europe/Stockholm" }).split(" ")[1];
};

export const parseTimeFromString = (time: string | undefined): number => {
  const date = time ? new Date(`${new Date().toISOString().split("T")[0]}T${time}`) : new Date();
  return date.getTime();
};

export const isBetweenTime = (
  workHourStart?: string,
  workHourEnd?: string,
  isWeekendHourCheck = false
): boolean => {
  if (!workHourStart || !workHourEnd) {
    return false;
  }

  if (!isWeekendHourCheck && isWeekend()) {
    return false;
  }

  const workingHourStart = parseTimeFromString(workHourStart);
  const workingHourEnd = parseTimeFromString(workHourEnd);
  const currentTime = parseTimeFromString(returnSwedishTimeString());
  return workingHourStart < currentTime && currentTime < workingHourEnd;
};
