import React from "react";
import { TeliaP } from "@teliads/components/react";

import styles from "./WorkHourDisplay.module.scss";

type WorkHourDisplayProps = {
  startHour?: string;
  endHour?: string;
  header?: string;
};

const WorkHourDisplay = ({ startHour, endHour, header }: WorkHourDisplayProps) => {
  if (!startHour || !endHour) {
    return null;
  }

  return (
    <div data-testid="card-work-hours">
      <TeliaP className={styles.workHoursHeader}>{header}</TeliaP>
      <TeliaP className={styles.workHours}>{`${startHour} - ${endHour}`}</TeliaP>
    </div>
  );
};

export { WorkHourDisplay };
