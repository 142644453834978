import React, { useCallback, useEffect, useState } from "react";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { GetOrganizationResponse as Organisation } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { corpSegment, supportCorpContact } from "@telia/b2x-rest-client";
import { supportCorpTechnician } from "@telia/b2x-support-rest-client";
import { TechnicianResponseBody } from "@telia/b2x-support-rest-client/dist/support-corp-technician";

import { CompanySegment } from "../../enums/company-segment";
import { ProviderStatus } from "../../enums/provider-status";
import { parseDedicatedChatDates } from "../../utils/dedicated-chat-dates-parser";
import { UserData, UserDataContext } from "./UserDataContext";

const companySegmentMap = new Map([
  ["LARGE", CompanySegment.LARGE],
  ["SME", CompanySegment.SME],
  ["SOHO", CompanySegment.SME],
  ["FUNCTION", CompanySegment.LARGE],
]);

const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [status, setStatus] = useState<UserData["status"]>({
    scopeId: ProviderStatus.LOADING,
    organisation: ProviderStatus.LOADING,
    corpContact: ProviderStatus.LOADING,
    companySegment: ProviderStatus.LOADING,
    personalTechnician: ProviderStatus.LOADING,
  });
  const [scopeId, setScopeId] = useState<UserData["scopeId"] | undefined>();
  const [organisations, setOrganisations] = useState<Organisation[] | undefined>();
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation>();
  const [corpContact, setCorpContact] = useState<UserData["corpContact"] | undefined>();
  const [companySegment, setCompanySegment] = useState<CompanySegment | undefined>();
  const [technicianData, setTechnicianData] = useState<TechnicianResponseBody | undefined>();

  // scopeId & organisations
  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      scopeId: ProviderStatus.LOADING,
    }));
    getScopeIdOrThrow()
      .then((scopeIdResponse: string) => {
        setStatus((prev) => ({ ...prev, scopeId: ProviderStatus.SUCCESS }));
        setScopeId(scopeIdResponse);

        setStatus((prev) => ({
          ...prev,
          organisation: ProviderStatus.LOADING,
        }));
        getOrganizations()
          .then((organisations) => {
            setStatus((prev) => ({ ...prev, organisation: ProviderStatus.SUCCESS }));
            setOrganisations(organisations);
            setSelectedOrganisation(organisations?.length ? organisations[0] : undefined);
          })
          .catch(() => {
            setStatus((prev) => ({ ...prev, organisation: ProviderStatus.FAILED }));
          });
      })
      .catch(() => {
        setStatus((prev) => ({ ...prev, scopeId: ProviderStatus.FAILED }));
      });
  }, []);

  // companySegment
  useEffect(() => {
    setStatus((prev) => ({ ...prev, companySegment: ProviderStatus.LOADING }));
    scopeId &&
      corpSegment.PublicService.getSegment1(scopeId)
        .then(({ results }) => {
          setStatus((prev) => ({ ...prev, companySegment: ProviderStatus.SUCCESS }));
          results?.mybusinessSegment &&
            setCompanySegment(companySegmentMap.get(results.mybusinessSegment));
        })
        .catch(() => {
          setStatus((prev) => ({ ...prev, companySegment: ProviderStatus.FAILED }));
        });
  }, [scopeId]);

  const updateSelectedOrganisation = useCallback(
    (organisationTscIds: string) => {
      if (!organisations?.length) {
        return;
      }

      const organisation = organisations.find(
        (organisation) => organisation.tscid && organisationTscIds.includes(organisation.tscid)
      );

      setSelectedOrganisation(organisation);
    },
    [organisations]
  );

  const fetchPersonalTechnician = useCallback(() => {
    setStatus((prev) => ({ ...prev, personalTechnician: ProviderStatus.LOADING }));
    supportCorpTechnician.TechnicianControllerService.getTechnician(
      selectedOrganisation?.tscid || "",
      scopeId
    )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((technician: any) => {
        setStatus((prev) => ({ ...prev, personalTechnician: ProviderStatus.SUCCESS }));
        const hasTechnicianData = Object.values(technician).some((prop) => prop);

        setTechnicianData(hasTechnicianData ? technician : undefined);
      })
      .catch(() => {
        setStatus((prev) => ({ ...prev, personalTechnician: ProviderStatus.FAILED }));
      });
  }, [selectedOrganisation?.tscid, scopeId]);

  const fetchCorpContacts = useCallback(() => {
    setStatus((prev) => ({ ...prev, corpContact: ProviderStatus.LOADING }));
    scopeId &&
      organisations &&
      supportCorpContact.ContactControllerService.getDedicatedContacts(
        selectedOrganisation?.tscid || "",
        scopeId
      )
        .then((corpContact) => {
          setStatus((prev) => ({ ...prev, corpContact: ProviderStatus.SUCCESS }));
          corpContact.dedicatedChat =
            corpContact?.dedicatedChat && parseDedicatedChatDates(corpContact?.dedicatedChat);
          setCorpContact(corpContact);
        })
        .catch(() => {
          setStatus((prev) => ({ ...prev, corpContact: ProviderStatus.FAILED }));
        });
  }, [scopeId, organisations, selectedOrganisation]);

  // Personal Technician
  useEffect(() => {
    selectedOrganisation && fetchPersonalTechnician();
  }, [selectedOrganisation, fetchPersonalTechnician]);

  // corpContact
  useEffect(() => {
    selectedOrganisation && fetchCorpContacts();
  }, [selectedOrganisation, fetchCorpContacts]);

  return (
    <UserDataContext.Provider
      value={{
        status,
        scopeId,
        organisations,
        selectedOrganisation,
        updateSelectedOrganisation,
        corpContact,
        companySegment,
        technicianData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export { UserDataProvider };
