import { DedicatedChat } from "@telia/b2x-rest-client/types/generated/services/support-corp-contact";

import { FALLBACK_SUPPORT } from "../../../constants/fallback-support";
import { isBetweenTime, isWeekend } from "../../../utils/time-utils";
import { AssignedContact } from "../ServiceDeskCard";

const shouldUseFallbackAndNumber = (dedicatedChat?: DedicatedChat): [boolean, string] => {
  if (!dedicatedChat) {
    return [true, FALLBACK_SUPPORT.SUPPORT_NUMBER];
  }

  // ServiceDesk uses "Other" to specify when to use otherSupportNumber. TODO: regex would be better here, but we don't have all possible outcomes for this field
  if (dedicatedChat.supportNumber && dedicatedChat.supportNumber.toLocaleLowerCase() !== "other") {
    return [false, dedicatedChat.supportNumber];
  }

  if (dedicatedChat.otherSupportNumber) {
    return [false, dedicatedChat.otherSupportNumber];
  }

  return [true, FALLBACK_SUPPORT.SUPPORT_NUMBER];
};

const serviceDeskCardStateMapper = (dedicatedChat?: DedicatedChat): AssignedContact => {
  const [isFallback, supportNumber] = shouldUseFallbackAndNumber(dedicatedChat);

  if (isFallback) {
    return {
      workHourStart: FALLBACK_SUPPORT.WORK_HOUR_START,
      workHourEnd: FALLBACK_SUPPORT.WORK_HOUR_END,
      supportNumber: supportNumber,
      isAvailable: isBetweenTime(FALLBACK_SUPPORT.WORK_HOUR_START, FALLBACK_SUPPORT.WORK_HOUR_END),
    };
  }

  return {
    workHourStart: dedicatedChat?.workingHourStartCall || "",
    workHourEnd: dedicatedChat?.workingHourEndCall || "",
    supportNumber: supportNumber,
    isAvailable: isWeekend()
      ? false
      : isBetweenTime(dedicatedChat?.workingHourStartCall, dedicatedChat?.workingHourEndCall),
  };
};

export { serviceDeskCardStateMapper };
