import React, { Children, ReactNode } from "react";
import { TeliaCol, TeliaRow } from "@teliads/components/react/commonjs";
import styles from "./CardsLayout.module.scss";

type CardsLayoutProps = {
  children: ReactNode;
};
const CardsLayout = ({ children }: CardsLayoutProps) => {
  const cards = Children.toArray(children);
  const slicedCards =
    cards.length > 2
      ? { firstRow: cards.slice(0, 1), secondRow: cards.slice(1) }
      : { firstRow: cards };

  return (
    <section className={styles.cardsContainer}>
      <TeliaRow data-testid="first-row" spacing="lg" gap={true}>
        {Children.map(slicedCards.firstRow, (card) => (
          <TeliaCol width={12} widthLg={6} widthMd={6}>
            {card}
          </TeliaCol>
        ))}
      </TeliaRow>
      {slicedCards.secondRow && (
        <TeliaRow data-testid="second-row" spacing="lg" gap={true}>
          {Children.map(slicedCards.secondRow, (card) => (
            <TeliaCol width={12} widthLg={4} widthMd={4}>
              {card}
            </TeliaCol>
          ))}
        </TeliaRow>
      )}
    </section>
  );
};

export { CardsLayout };
