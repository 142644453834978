export const salesTeamsList = [
  "SME Sales Nykund",
  "SME Sales Luleå1",
  "SME Sales Luleå 1",
  "SME Sales Luleå2",
  "SME Sales Luleå 2",
  "SME Sales Svall",
  "SME Sales Sthlm1",
  "SME Sales Sthlm 1",
  "SME Sales Sthlm2",
  "SME Sales Sthlm 2",
  "SME Sales Gbg",
  "SME Sales Malmö",
];
