import { PageReadableKey } from "./page-readable-key";

const resource = PageReadableKey.CONTACT;

const translationKeys = {
  header: {
    heading: `${resource}.header.heading`,
  },
  cards: {
    loadingPlaceholder: `${resource}.cards.loadingPlaceholder`,
    errorHeading: `${resource}.cards.loadingPlaceholder`,
    errorContent: `${resource}.cards.error.content`,
  },
  salesContact: {
    heading: `${resource}.salesContact.heading`,
    preamble: `${resource}.salesContact.preamble`,
    loadingPlaceholder: `${resource}.salesContact.loadingPlaceholder`,
  },
  serviceDeskCard: {
    heading: `${resource}.serviceDesk.heading`,
    preamble: `${resource}.serviceDesk.preamble`,
    badgeHeader: `${resource}.serviceDesk.badgeHeader`,
    badgeValue: {
      open: `${resource}.serviceDesk.badgeValue.open`,
      closed: `${resource}.serviceDesk.badgeValue.closed`,
    },
    workHoursHeader: `${resource}.serviceDesk.workHoursHeader`,
  },
  questionCard: {
    heading: `${resource}.question.heading`,
    preamble: `${resource}.question.preamble`,
    subTitle: `${resource}.question.askQuestion`,
    buttonText: `${resource}.question.buttonText`,
  },
  personalTechnician: {
    heading: `${resource}.personalTechnician.heading`,
    description: `${resource}.personalTechnician.description`,
    contactLabel: `${resource}.personalTechnician.contactLabel`,
    details: { workHoursHeader: `${resource}.personalTechnician.details.workHoursHeader` },
    buttonText: `${resource}.personalTechnician.buttonText`,
  },
  stockholmChatCard: {
    heading: `${resource}.stockholmChat.heading`,
    preamble: `${resource}.stockholmChat.preamble`,
    badgeHeader: `${resource}.stockholmChat.badgeHeader`,
    badgeValue: {
      open: `${resource}.stockholmChat.badgeValue.open`,
      closed: `${resource}.stockholmChat.badgeValue.closed`,
    },
    workHoursHeader: `${resource}.stockholmChat.workHoursHeader`,
    weekendWorkHoursHeader: `${resource}.stockholmChat.weekendWorkHoursHeader`,
    buttonText: `${resource}.stockholmChat.buttonText`,
  },
  organisationPicker: {
    organisationSelectLabel: `${resource}.orgPicker.organisationSelect`,
  },
};

export default translationKeys;
