import React from "react";
import ReactDOMClient from "react-dom/client";
import { TranslationLoader } from "@telia/b2b-microcopy-translation";
import singleSpaReact from "single-spa-react";

// registering Voca components for usage across the app
import "@teliads/components/react";
import "@telia/b2b-layout";
import Chat from "./components/chat/Chat";
import Contact from "./pages/Contact";
import { UserDataProvider } from "./providers/user-data/UserDataProvider";
import { PageReadableKey } from "./translation/page-readable-key";

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: () => (
    <UserDataProvider>
      <TranslationLoader resourceSetKeys={[PageReadableKey.CONTACT]}>
        <Contact />
        <Chat />
      </TranslationLoader>
    </UserDataProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
