import { useContext, useEffect, useState } from "react";

import { UserData, UserDataContext } from "../../providers/user-data/UserDataContext";

const useUserData = <T = UserData>(selector?: (userData: UserData | null) => T): T => {
  const userData = useContext(UserDataContext);
  const [selectedUserData, setSelectedUserData] = useState<T>();

  useEffect(() => {
    selector && setSelectedUserData(selector(userData));
  }, [selector, userData]);

  return (selector ? selectedUserData : userData) as T;
};

export { useUserData };
